<!--
  Spinner to indicate something is busy
-->

<template>
  <v-progress-circular
    indeterminate
    color="grey darken-1"
    size="24"
    width="3"
  ></v-progress-circular>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>
