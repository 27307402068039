<!--
  Component which is listed in the timeline list
-->

<template>
  <v-card
    v-if="documentTypesMixinReady"
    class="mx-auto mb-2 no-focus"
    min-height="100"
    outlined
    @click.stop.prevent="cardClicked()"
  >
    <v-card-title
      class="text-subtitle-1"
    >
      <div><span class="d-inline font-weight-medium">{{ documentType }}</span>
      – {{ title }}<span v-if="fragDocu"> {{ fragDocu | fragDocufy }}</span></div>
    </v-card-title>

    <!-- NL -->
    <v-card-text
      v-if="documentText"
      class="mt-n2 mb-n4"
    >
      {{ documentText | quotify }}
    </v-card-text>
    <v-card-text
      v-if="subjects && subjects.length > 0"
      class="mt-n2 mb-n4"
    >
    </v-card-text>

    <v-card-text v-if="subjects && subjects.length > 0" class="mt-n2 mb-n4">
      <strong>Onderwerpen</strong><br>
      {{ subjectsAsString }}
    </v-card-text>
    <!-- /NL -->

    <!-- EU -->
    <v-card-text v-if="epCommittees" class="mt-n2 mb-n6">
      {{ epCommittees }}
    </v-card-text>

    <v-card-text v-if="isEcOrEp && eventDate" class="mt-n2 mb-n6">
      <strong>Vergaderdatum: </strong>{{ eventDate }}
    </v-card-text>

    <v-card-text v-if="epReference" class="mt-n2 mb-n6">
      <strong>Referentienummer(s): </strong>{{ epReference }}
    </v-card-text>

    <v-card-text v-if="epProcedure" class="mt-n2 mb-n6">
      <strong>Procedurenummer(s): </strong>{{ epProcedure }}
    </v-card-text>
    <!-- /EU -->

    <!-- LOCREG -->
    <!-- Here we put the entries for localRegional Dashboard -->
      <v-card-text v-if="dlrcMeetings && dlrcMeetings.length > 0" class="mt-n2 mb-n6">
        <strong>Vergadering: </strong>
          <a
            :href="dlrcMeetings[0].url"
            target="_blank"
            class="text--primary text-decoration-none"
          >
            {{ dlrcMeetings[0].subject }}
          </a>
      </v-card-text>

      <v-card-text v-if="dlrcAgendaItems && dlrcAgendaItems.length > 0" class="mt-n2 mb-n6">
        <strong>Agendapunt: </strong>
        <a
          :href="dlrcAgendaItems[0].url"
          target="_blank"
          class="text--primary text-decoration-none"
        >
          {{ dlrcAgendaItems[0].title }}
        </a>
      </v-card-text>

      <v-card-text v-if="isDlrc && dlrcVoteResults" class="mt-n2 mb-n6">
        <strong>Stemmingsuitslag: </strong> {{ dlrcVoteResults }}
      </v-card-text>


    <v-card-text v-if="url" class="mt-n2 mb-n4">
      Bron: <a
        :href="url"
        target="_blank"
        class="text--primary text-decoration-none"
      >{{ url | urlToSourceName }}</a>
    </v-card-text>
    <v-card-text
      v-if="isNotEuAndNotDlrc && (sourceUrl && !url)"
      class="mt-n2 mb-n4"
    >
      Bron:
      <span
        v-if="!url"
        @click.stop.prevent="openSourceUrlInNewWindow(sourceUrl)"
      >{{ sourceUrl | urlToSourceName }}</span>
    </v-card-text>

    <v-card-actions class="mt-n2">
      <list-timeline-item-category
        :type="type"
        :percentage="percentage"
        :institution="institution"
        :sourceUrl="sourceUrl"
      />
      <v-spacer />

      <!-- <v-btn text
        outlined
        @click="showTeaserDialog()"
        v-if="!authHasModuleAccess('MC04')"
      >
        Aan lijst toevoegen
      </v-btn> -->
      <v-btn
        text
        outlined
        @click.stop.prevent="addToList()"
        v-if="authHasModuleAccess('MC04')"
      >
        + Lijst
      </v-btn>

      <!-- <v-btn v-if="!url" text :to="{ path: actionUrl }" outlined>Details</v-btn> -->
      <v-btn
        v-if="!url"
        text
        @click.stop.prevent="goToUrlConditionally()"
        outlined
      >
        Details
      </v-btn>
      <v-btn
        v-if="url"
        text
        @click.stop.prevent="openUrlInNewWindow()"
        outlined
      >
        Bekijk bericht
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import PropValidators from '@/propValidators';
import ListTimelineItemCategory from '@/components/ListTimelineItemCategory.vue';
import ListTimelineItemForm from '@/components/ListTimelineItemForm.vue';
import { showDialog } from '@/helpers/dialogHelper';
import DocumentTypesMixin from '@/mixins/DocumentTypesMixin';
import replace from '../filters/replace';
import quotify from '../filters/quotify';
import urlToSourceName from '../filters/urlToSourceName';
import fragDocufy from '../filters/fragDocufy';
import FeatureTeaserListManagerVue from './FeatureTeaserListManager.vue';

export default {
  name: 'ListTimelineItem',

  components: {
    ListTimelineItemCategory,
  },

  mixins: [DocumentTypesMixin],

  filters: {
    urlToSourceName,
    quotify,
    replace,
    fragDocufy,
  },

  props: {
    // Document id
    id: {
      type: String,
      required: true,
    },
    // Document title
    title: {
      type: String,
      required: true,
    },
    // Document text
    documentText: {
      type: String,
      required: false,
    },
    // Document url
    url: {
      type: String,
      required: false,
    },
    // Document sourceUrl in case of non rss docs
    sourceUrl: {
      type: String,
      required: false,
    },
    // Document type
    type: {
      type: String,
      required: true,
      default: 'default',
    },
    // Matching percentage
    percentage: {
      required: false,
      validator: PropValidators.percentage,
    },
    // Word highlighted in document
    highlightWord: {
      type: String,
      required: true,
    },
    // Subjects of document
    subjects: {
      type: Array,
      required: false,
    },
    // EU institution
    institution: {
      type: String,
      required: false,
    },
    // EU fragDocu
    fragDocu: {
      type: String,
      required: false,
    },
    // EU committees
    committees: {
      type: Array,
      required: false,
    },
    // EU relations
    relations: {
      type: Array,
      required: false,
    },
    // EU authorities
    authorities: {
      type: String,
      required: false,
    },
    // EU eventDate
    eventDate: {
      type: String,
      required: false,
    },
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      userFilters: 'userFilters',
    }),

    isEcOrEp() {
      return this.institution && (this.institution === 'EC' || this.institution === 'EP');
    },

    isDlrc() {
      return this.type.startsWith('CC-') || this.type.startsWith('PC-');
    },

    isNotEuAndNotDlrc() {
      return (!this.isEcOrEp && !this.isDlrc);
    },

    epCommitteesList() {
      const { euEpCommittees } = this.userFilters;
      return euEpCommittees;
    },

    actionUrl() {
      const highlightedWord = encodeURIComponent(this.highlightWord);
      let destination = '/document';
      if (this.type.startsWith('EUROPARL-') || this.type.startsWith('EUROCOM-')) {
        destination = '/eu/document';
      }
      if (this.type.startsWith('CC-') || this.type.startsWith('PC-')) {
        destination = '/nl-lr/document';
      }
      return `${destination}/${this.id}/${highlightedWord}`;
    },

    dateAsString() {
      return this.$moment(this.date).format('DD-MM-YYYY');
    },

    eventDateAsString() {
      return this.$moment(this.eventDate).format('d MMMM YYYY');
    },

    subjectsAsString() {
      const keywords = this.subjects.map((sub) => sub.keyword);
      return keywords.join(', ');
    },

    documentType() {
      const loc = this.getLocalizationForDocTypeId(this.type);
      return typeof loc === 'string' ? loc : loc.singular;
    },

    // Don't use this. Just to try out stuff. might become handy though.
    allReferences() {
      const sec = this.data.metadata.secReferenceList ? this.data.metadata.secReferenceList[0].id : '';
      const swd = this.data.metadata.swdReferenceList ? this.data.metadata.swdReferenceList[0].id : '';
      const com = this.data.metadata.comReferenceList ? this.data.metadata.comReferenceList[0].id : '';
      const join = this.data.metadata.joinReferenceList ? this.data.metadata.joinReferenceList[0].id : '';

      const text = [sec, swd, com, join].filter(Boolean).join(', ');

      return text;
    },

    epCommittees() {
      const result = [];
      if (this.committees) {
        this.committees.forEach((item) => {
          this.epCommitteesList.forEach((obj) => {
            if (obj.code === item) {
              result.push(obj.nl);
            }
          });
        });
      }
      return result.toString();
    },

    epReference() {
      const reference = [];
      if (this.relations) {
        this.relations.forEach((item) => {
          if (item.type === 'DOSSIER') {
            reference.push(item.code);
          }
        });
      }
      return reference.toString().replace(',', ', ');
    },

    epProcedure() {
      const procedure = [];
      if (this.relations) {
        this.relations.forEach((item) => {
          if (item.type === 'PROCEDURE') {
            procedure.push(item.code);
          }
        });
      }
      return procedure.toString().replace(',', ', ');
    },

    yearNow() {
      const dateNow = new Date();
      const yearNow = dateNow.getFullYear();
      return yearNow;
    },

    dlrcMeetings() {
      if (!this.data.related) return [];
      const dlrcMeetings = [];
      if (this.data.related.meetings && this.data.related.meetings.length > 0) {
        this.data.related.meetings.forEach((item) => {
          item.meeting = true;
          dlrcMeetings.push(item);
        });
      }
      return dlrcMeetings;
    },

    dlrcAgendaItems() {
      if (!this.data.related) return [];
      const dlrcAgendas = [];
      if (this.data.related.agendaItems && this.data.related.agendaItems.length > 0) {
        this.data.related.agendaItems.forEach((item) => {
          item.agendaItem = true;
          dlrcAgendas.push(item);
        });
      }
      return dlrcAgendas;
    },

    dlrcVoteResults() {
      if (!this.data.related.dossier) return null;
      if (this.data.related.dossier.voteResults) {
        return this.data.related.dossier.voteResults;
      }
      return null;
    },
  },

  methods: {
    showTeaserDialog() {
      showDialog(FeatureTeaserListManagerVue);
    },

    cardClicked() {
      return this.url ? this.openUrlInNewWindow() : this.goToUrlConditionally();
    },

    goToUrlConditionally() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$router.push({ path: this.actionUrl });
      } else {
        window.open(this.actionUrl, '_blank');
      }
    },

    openUrlInNewWindow() {
      window.open(this.url, '_blank');
    },

    openSourceUrlInNewWindow(url) {
      window.open(url, '_blank');
    },

    addToList() {
      this.$gtag.event('timeline-update-add-to-list', {
        event_category: 'timeline',
      });

      showDialog(ListTimelineItemForm, {
        document: {
          id: this._props.id,
          date: this._props.data.date,
          type: this._props.data.type,
          sourceUrl: this._props.data.sourceUrl,
        },
        title: this._props.title,
      });
    },
  },

};
</script>

<style lang="scss" scoped>
.no-focus:focus::before {
  opacity: 0 !important;
}
</style>
