<!--
  Component which lists the timeline items
-->

<template>
  <div>
      <v-subheader
        v-if="timelineItems.length > 0"
        class="text-subtitle-1 text--primary d-inline-block"
      >
        Zoekperiode: <span class="font-weight-medium">{{ this.expandedDateRange ? maxDateRangeTimelineItemsTotal : countTotal }} </span> van totaal
          <span class="font-weight-medium">{{ maxDateRangeTimelineItemsTotal }}</span> resultaten
      </v-subheader>

    <v-divider
      v-if="timelineItems.length > 0"
    />

    <template>
      <div
        v-for="(timelineItem, index) in timelineItems"
        :key="index"
      >
        <v-subheader
          class="date-header text-body1 text-uppercase text--primary font-weight-medium"
          v-if="isNewDate(index)"
          :key="`sh-${timelineItem.id}`"
        >
          {{ dateAsString(timelineItem.date) }}
        </v-subheader>

        <list-timeline-item
          :key="timelineItem.id"
          :id="timelineItem.id"
          :highlight-word="timelineItem.highlightWord"
          :url-detail="timelineItem.actions.details"
          :title="timelineItem.title"
          :document-text="timelineItem.documentText"
          :url="timelineItem.url"
          :source-url="timelineItem.sourceUrl"
          :type="timelineItem.type"
          :percentage="timelineItem.match"
          :subjects="timelineItem.subjects"
          :institution="timelineItem.institution"
          :fragDocu="timelineItem.fragDocu"
          :committees="timelineItem.committees"
          :relations="timelineItem.relations"
          :authorities="timelineItem.authorities"
          :eventDate="timelineItem.eventDate"
          :data="timelineItem"
        />
      </div>
    </template>
    <v-btn
      v-if="hasMore"
      @click="loadMore()"
      block
    >
      Toon meer
    </v-btn>

    <v-btn
      v-if="!hasMore && this.$route.query.startDate
        && (new Date(this.$route.query.startDate).getFullYear() === 1970)
        && prevTotal < timelineItems.length"
      @click="loadMore()"
      block
    >
      Toon meer
    </v-btn>

    <v-btn
      v-if="!hasMore
        && new Date(this.$route.query.startDate).getFullYear() !== 1970
        && ((countTotal > 0 && countTotal === timelineItems.length)
          || (countTotal === 0 && lookBack))"
      @click="expandDateRange()"
      block
    >
      Kijk verder terug
    </v-btn>

    <v-btn
      v-if="busyLoadingMore && !loaded"
      block
    >
      <spinner />
    </v-btn>
  </div>
</template>

<script>
import ListTimelineItem from '@/components/ListTimelineItem.vue';
import Spinner from './Spinner.vue';

export default {
  name: 'ListTimeline',

  $el: '#results',

  components: {
    // ContentPanel,
    ListTimelineItem,
    Spinner,
  },

  props: {
    maxDateRangeTimelineItemsTotalString: {
      type: String,
      required: true,
    },
    nextItemUrlString: {
      type: String,
      required: true,
    },
    setNextItemUrlString: {
      type: String,
      required: true,
    },
    timelineItemsString: {
      type: String,
      required: true,
    },
    timelineItemsCountTotalString: {
      type: String,
      required: true,
    },
    timelineItemsHasMoreString: {
      type: String,
      required: true,
    },
    getAllTimelineItemsNextString: {
      type: String,
      required: true,
    },
    getAllTimelineItemsString: {
      type: String,
      required: true,
    },
    maxDateRangeTimelineItemsHasMoreString: {
      type: String,
      required: true,
    },
  },

  computed: {
    maxDateRangeTimelineItemsTotal() {
      return this.$store.getters[this.maxDateRangeTimelineItemsTotalString];
    },

    nextItemUrl() { return this.$store.getters[this.nextItemUrlString]; },

    timelineItems() { return this.$store.getters[this.timelineItemsString]; },

    countTotal() { return this.$store.getters[this.timelineItemsCountTotalString]; },

    expandedDateRange() { return this.$route.query.startDate === new Date(0).toISOString(); },

    hasMore() {
      return this.$store.getters[this.expandedDateRange
        ? this.maxDateRangeTimelineItemsHasMoreString
        : this.timelineItemsHasMoreString
      ];
    },

    loaded() {
      if (this.expandedDateRange) {
        return (this.maxDateRangeTimelineItemsTotal === this.timelineItems.length);
      }
      return this.countTotal === this.timelineItems.length;
    },

    lookBack() { return this.$store.getters.lookBack; },
  },

  data() {
    return {
      busyLoadingMore: false,
      benched: 0,
      mainContentContainer: null,
      prevTotal: 0,
    };
  },

  created() {
    window.addEventListener('scroll', this.scrollTimeline);
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrollTimeline);
  },

  methods: {
    async scrollTimeline() {
      // https://stackoverflow.com/questions/45121034/infinite-scrolling-with-ajax-does-not-work-with-chrome
      const reachedBottom = document.documentElement.scrollTop
          + window.innerHeight >= (document.documentElement.offsetHeight - 100);
      if (reachedBottom && this.busyLoadingMore === false) {
        await this.loadMore();
      }
    },

    isNewDate(index) {
      return (
        index === 0
        || this.timelineItems[index - 1].date !== this.timelineItems[index].date
      );
    },

    isFirstItemOfDate(index) {
      return (
        index === 0
        || (this.timelineItems[index - 1]
        && this.timelineItems[index - 1].date !== this.timelineItems[index].date)
      );
    },

    isLastItemOfDate(index) {
      return (
        index === this.timelineItems.length - 1
        || (this.timelineItems[index + 1]
        && this.timelineItems[index + 1].date !== this.timelineItems[index].date)
      );
    },

    dateAsString(date) {
      return this.$moment(date).format('dddd D MMMM YYYY');
    },

    async loadMore() {
      this.busyLoadingMore = true;
      this.prevTotal = await this.timelineItems.length;
      // We have to check whether there are already timeline items.
      // If not then fire the initial call again with a wider date range.
      if (this.timelineItems.length > 0) {
        await this.$store.dispatch(this.getAllTimelineItemsNextString);
      } else {
        await this.$store.dispatch(this.getAllTimelineItemsString);
      }
      this.busyLoadingMore = false;
    },

    async expandDateRange() {
      // Expand date range to the max
      const dateRangeISO = {
        startDate: new Date(0).toISOString(),
        endDate: new Date().toISOString(),
      };

      this.$store.dispatch('setLookBack', false);
      this.$store.dispatch('setDateRange', dateRangeISO);

      await this.$router.replace({
        name: this.$route.name,
        query: { ...this.$route.query, ...dateRangeISO },
      }).catch(() => {});
      await this.loadMore();
    },
  },
};
</script>

<style scoped lang="scss">
  .date-header {
    letter-spacing: 0.1rem;
  }
</style>
