<template>
  <div>
    <v-menu
      v-if="!isIE11"
      v-model="isOpened"
      offset-y
      :return-value.sync="dateRange"
    >
      <template
        v-slot:activator="{ on, attrs }"
      >
        <v-text-field
          v-model="formattedDateRangeText"
          prepend-icon="mdi-calendar-range"
          color="#0c567a"
          outlined
          dense
          style="height: 40px;"
          autocomplete="off"
          @input="onKeyUp()"
          v-bind="attrs"
          v-on="on"
        />
      </template>

      <v-list>
        <v-list-item @click="dateRangeInMonths(1)">
          <v-list-item-title>Vorige maand</v-list-item-title>
        </v-list-item>
        <v-list-item @click="dateRangeInMonths(6)">
          <v-list-item-title>Vorige 6 maanden</v-list-item-title>
        </v-list-item>
        <v-list-item @click="dateRangeInMonths(12)">
          <v-list-item-title>Vorige 12 maanden</v-list-item-title>
        </v-list-item>
        <v-list-item @click="emptyDateRange()">
          <v-list-item-title>Toon alles</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="modal = true"
        >
          <v-list-item-title>Anders...</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-text-field
      v-if="isIE11"
      v-model="formattedDateRangeText"
      prepend-icon="mdi-calendar-range"
      color="#0c567a"
      outlined
      dense
      style="height: 40px;"
      autocomplete="off"
      @input="onInput"
      @keyup.enter.native="onKeyUp()"
    />

    <v-dialog
      v-if="modal"
      v-model="modal"
      persistent
      width="290px"
    >
      <v-date-picker
        ref="datePicker"
        v-model="dates"
        range
        no-title
        locale="nl-nl"
      >
        <v-spacer />
        <v-btn
          color=""
          @click="modal = false"
        >
          Annuleer
        </v-btn>
        <v-btn
          color="teal
          lighten-1"
          class="white--text"
          @click="saveRange"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {

  data: () => ({
    isOpened: false,
    valid: false,
    rawDateString: '',
    dates: [],
    modal: false,
    empty: false,
    // dateRules: [
    //   v => /\s+\b(\w*tot\w*)\b\s+(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})|(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})\s+\b(\w*tot\w*)\b\s+(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})/.test(v) || '',
    // ],
  }),

  props: {
    getTimelineItemsString: {
      type: String,
      required: true,
    },
  },

  methods: {
    dateRangeInMonths(numOfMonths) {
      this.empty = false;
      const end = new Date();
      end.setHours(0, 0, 0, 0);
      const start = new Date();

      start.setHours(0, 0, 0, 0);
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * numOfMonths);

      // return [start, end];
      this.setDates([start, end]);
      // this.dateRange = this.dates;
    },

    emptyDateRange() {
      this.empty = true;
      this.setDates([new Date(0), new Date()]);
    },

    saveRange() {
      this.dateRange = this.dates;
      this.modal = false;
    },

    onInput() {
      this.isOpened = false;
    },

    onKeyUp() {
      this.onInput();
      // On user input we have to split a string apart, clean it from whitespace and then format as an array of date objects
      // Check for dates in dd-mm-yyyy tot dd-mm-yyyy notation. The whitespace between the parts may vary
      this.valid = /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})\s+\b(\w*tot\w*)\b\s+(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})$/.test(this.rawDateString);
      if (this.valid) {
        const dates = this.rawDateString.split('tot').map((item) => moment(item.trim(), ['DD-MM-YYYY']).format());
        this.dates = dates;
        this.saveRange();
      } else {
        this.$store.dispatch('setMessage', {
          message: 'Gebruik "DD-MM-YYYY tot DD-MM-YYYY" notatie',
          type: 'info',
        });
      }
    },

    setDates(tempArray) {
      this.dates = tempArray;
    },

  },

  computed: {
    ...mapGetters({
      isIE11: 'isIE11',
    }),
    dateRange: {
      get() {
        const tempArray = this.$store.getters.searchParameterDateRange;
        // This is to avoid side effects which are very bad practice!
        // https://github.com/vuejs/eslint-plugin-vue/blob/master/docs/rules/no-side-effects-in-computed-properties.md
        this.setDates(tempArray);
        return tempArray;
      },
      set(dateRange) {
        this.$gtag.event('search-filter-change-date-range', {
          event_category: 'search-filter',
        });

        let updatedDateRange = dateRange;

        if (this.dates && this.dates.length === 2) {
          updatedDateRange = this.dates;

          if (updatedDateRange[0] > updatedDateRange[1]) {
            updatedDateRange = [updatedDateRange[1], updatedDateRange[0]];
          }
          const dateRangeISO = {
            startDate: updatedDateRange ? new Date(updatedDateRange[0]).toISOString() : '',
            endDate: updatedDateRange ? new Date(updatedDateRange[1]).toISOString() : '',
          };

          this.$router.push({
            name: this.$route.name,
            query: { ...this.$route.query, ...dateRangeISO },
          }).catch(() => {});

          this.$store.dispatch('setDateRange', dateRangeISO);
          this.$store.dispatch(this.getTimelineItemsString);
        }
      },
    },

    formattedDateRangeText: {
      get() {
        let formattedRange = [moment(this.dateRange[0]).format('DD-MM-YYYY'), moment(this.dateRange[1]).format('DD-MM-YYYY')];
        if (new Date(this.dates[0]).getFullYear() === 1970) {
          formattedRange = ['', moment(this.dateRange[1]).format('DD-MM-YYYY')];
        }
        return `${formattedRange.join('  tot  ')}`;
      },
      set(val) {
        this.rawDateString = val;
      },
    },
  },

  watch: {
    formattedDateRangeText(val) {
      this.rawDateString = val;
    },
  },
};
</script>
